<template>
    <div class="desktopLayoutClass">
    <v-app>

      <div>
                <v-row justify="center">
                  <v-dialog v-model="auth_error_enable" persistent max-width="290">
                    <v-card>
                      <v-card-title style="word-break:normal">
                      {{$t("Warning_Message.Unauthorized")}}
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeDialogUnauthorized" style="background-color: #1467BF;">
                          {{$t("Warning_Message.Ok")}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>

              <div>
                <v-row justify="center">
                  <v-dialog v-model="error_enable" persistent max-width="290">
                    <v-card>
                      <v-card-title style="word-break:normal">
                         {{$t("Warning_Message.Something_Went_Wrong")}}
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green darken-1" text @click="closeDialogError" style="background-color: #1467BF;">
                          {{$t("Warning_Message.Ok")}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>

      <div class="languageMainDiv">
      <div
        v-show="show_header"
        class="header"
        style="
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          padding-left: 5px;
           background-color: white
        "
        >
        <div style="display: flex; flex-direction: row; width: 100%;">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToHomePage()"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt"
              >{{ $t("Customer.Language.Language") }}</span
            >
          </div>
        </div>
      </div>

      <div class="LanguageClass" v-if="!isLoadingPage">
			<div style="padding-top: 15px;display: flex;flex-direction: column;" align="left" v-if="!isLoading">
				<v-card style="box-shadow: 0px 8px 20px 0px #0547891A; border: 1px solid #E4F5FF; border-radius: 8px; height: 130px;">
				<div style="margin: 10px">
					<div style='display: flex; flex-direction: row; padding:10px;height:58px;'  @click="changeLanguage('en')">
            <div>
            	<img src="https://s3iconimages.mymedicine.com.mm/usa_flag.svg"  style="margin-bottom:-4px;"/>
             <span text-align="left" style="margin-left:20px;" class="css-title">{{$t("Customer.Language.English")}}</span>
            </div>
            <div  style="flex:1;text-align:right;">
              <span v-if="$i18n.locale=='en'" class="content"><img src="https://s3iconimages.mymedicine.com.mm/circle_tick.svg" /></span>
            </div>
          </div>
					<v-divider/>
					<div style='display: flex; flex-direction: row; padding:10px; height:58px;' @click="changeLanguage('mm')">
            <div >
            	<img src="https://s3iconimages.mymedicine.com.mm/myanmar_flag.svg" style="margin-bottom:-4px; margin-top:5px"/>
             <span text-align="left" style="margin-left:20px;" class="css-title">{{$t("Customer.Language.Myanmar")}}</span>
            </div>
            <div style="flex:1;text-align: right;">
              <span v-if="$i18n.locale=='mm'" class="content"><img src="https://s3iconimages.mymedicine.com.mm/circle_tick.svg" /></span>
            </div>
          </div>
		      <v-divider/>
				</div>
				</v-card>
				<br>
			</div>
      <div v-else style="margin-top:25px;">
        <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
      </div>
      </div>
      <div v-else style="margin-top:25px;">
        <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
      </div>
      </div>
    </v-app>
    </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
import bottomnavbar from "./bottomnavbar.vue";
export default {
  name: "Language",
  data(){
      return{
          user_id: "",
          currentCustomer : "",
          auth_error_enable : false,
		    	error_enable : false,
          isMobile : false,
          isLoading:false,
          isLoadingPage: true,
          show_header: true,
      }
  },
  methods:{
    changeLanguage(language){
      //axios call
      this.isLoading = true
      var language_body = {
        "language": language,
        "token" : this.currentCustomer,
        "typeOfUser" : "CUSTOMER"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL+"/updateLanguage",language_body)
      .then((changeLanguageResponse) =>{
        this.$i18n.locale = language;
        this.$store.dispatch("changeLocale", this.$i18n.locale);
        this.isLoading = false;

      })
      .catch((changeLanguageError) =>{
         this.isLoading = false;
        if(changeLanguageError.response.status == 401){
            this.auth_error_enable = true;
          }
          else{
            this.error_enable = true;
          }
      });
      //this.$router.go();
    },
    closeDialogUnauthorized(){
			this.auth_error_enable = false;
			this.$router.push({
				name: "Login",
			});
		},
		closeDialogError(){
			this.error_enable = false;
		},
    redirectToHomePage() {
      this.$router.go(-1);
    },
    userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'changeLanguage',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'changeLanguage',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
    },
  },
  mounted(){
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    // document.title = "Language"
    if(this.$store.state.locale == "en")
      document.title = "Language"
    else
      document.title = "ဘာသာစကား"
    this.$i18n.locale = this.$store.state.locale;
    this.isLoadingPage = false;
    this.currentCustomer = this.$cookies.get("customerToken");
		var access = this.$cookies.get("access");
    if(!this.currentCustomer){
      this.$router.push({
        name : "Login"
      })
    }
    else {
      this.userPageViewTable();
    }
    // else{
    //     var verifyCustomerTokenData = {
    //       "token" : this.currentCustomer,
    //       "access" : access,
    //       "typeOfUser" : "CUSTOMER"
    //     }
    //     axios.post(process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",verifyCustomerTokenData)
    //     .then((verifyCustomerTokenDataResponse) => {
    //       this.language = verifyCustomerTokenDataResponse.data.data.language;
    //       this.$i18n.locale = this.language;
    //       this.isLoadingPage = false;
    //     })
    //     .catch((verifyCustomerTokenDataError)=>{
    //       if(verifyCustomerTokenDataError.response.status == 401){
    //         this.auth_error_enable = true;
    //          this.isLoadingPage = false;
    //       }
    //       else{
    //         this.error_enable = true;
    //          this.isLoadingPage = false;
    //       }
    //     })
    // 	}
}
}

</script>

<style>
/* @media (min-width: 800px){
  .languageMainDiv{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;

  }
  .LanguageClass{
    margin-top: 2% !important;
  }
  } */
</style>
